import axios from "axios";
import router from "../../router/index";
import store from "../index";

// const baseURL = process.env.VUE_APP_API_BASE_URL;


const baseURL = process.env.VUE_APP_API_BASE_URL;

export default {
  namespaced: true,
  state: {
    users: [],
    user: ''
  },
  mutations: {
    SET_ALL_USERS(state, payload) {
      state.users = payload;
    },
    SET_USER(state, payload){
      state.user = payload
    }
  },
  actions: {
   
    async setAllUsers(context) {
     const user = JSON.parse(localStorage.getItem('accesstoken'));
      await axios
        .get(`${baseURL}/api/auth/get`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + user,
          },
        })
        .then(async (res) => {
          if (res.status === 200) {
            context.commit("SET_ALL_USERS", res.data.data);
          }
        })
        .catch((err) => {
        console.log("Error",err)
        });
    },
    async setUser(context, id){
      await axios 
        .get(`${baseURL}/api/auth/get/${id}`)
        .then(async (res) => {
          if (res.status === 200) {
            context.commit("SET_USER", res.data.data);
          }
        })
        .catch((err) => {
        console.log("Error",err)
        });
    },
    async update(context, userData) {
     const user = JSON.parse(localStorage.getItem("accesstoken"));
      
      await axios
        .put(`${baseURL}/api/auth/reset/${userData.id}`, userData, 
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + user,
          },
        })
        .then(async (res) => {
          if (res.status === 200) {
            store.dispatch("Auth/setAllUsers");
            router.replace({ name: "user" });
          }
        })
        .catch((err) => {
        console.log("Error", err)
        });
    }, 
  },
  getters: {},
};
