<template>
  <div id="app">
    <!-- <DashboardComponent /> -->
    <!-- <LoginComponent /> -->

    <!-- <router-view></router-view> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import NavbarComponent from './components/inc/navbarComponent'
// import MainView from "@/views/MainView.vue";
// import DashboardComponent from "./components/inc/DashboardComponent.vue";
// import LoginComponent from "./components/auth/loginView.vue"
export default {
  name: 'App',
  components: {
    // DashboardComponent,
    // LoginComponent
  }
}
</script>

<style scoped>

</style>
