import axios from "axios";
import router from "../../router/index";
import store from "../index";
const baseURL = process.env.VUE_APP_API_BASE_URL;
export default {
  namespaced: true,
  state: {
    Partners:[],
    partner: ''
  },
  mutations: {
    SET_ALL_PARTNERS(state, payload) {
      state.Partners = payload;
    },
    SET_PARTNER(state, payload){
      state.partner = payload
    }
  },
  actions: {
   
    async setPartners(context) {
     const user = JSON.parse(localStorage.getItem('accesstoken'));
      await axios
        .get(`${baseURL}/api/partner/get`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + user,
          },
        })
        .then(async (res) => {
          if (res.status === 200) {
            context.commit("SET_ALL_PARTNERS", res.data.data);
          }
        })
        .catch((err) => {
        console.log("Error",err)
        });
    },
    async setPartner(context, id){
      await axios 
        .get(`${baseURL}/api/partner/get/${id}`)
        .then(async (res) => {
          if (res.status === 200) {
            context.commit("SET_PARTNER", res.data.data);
          }
        })
        .catch((err) => {
        console.log("Error",err)
        });
    },
    async create(context, formData) {
     const user = JSON.parse(localStorage.getItem("accesstoken"));   
      await axios
        .post(`${baseURL}/api/partner/create`, formData, 
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + user,
          },
        })
        .then(async (res) => {
          if (res.status === 200) {
            store.dispatch("Partner/SET_ALL_PARTNERS");
            router.replace({ name: "partner" });
          }
        })
        .catch((err) => {
        console.log("Error", err)
        });
    }, 
    async update(context, formData){
     const user = JSON.parse(localStorage.getItem("accesstoken"));
     let id = "";
     for (const value of formData.values()) {
      id = value;
    }
    console.log("id", id)
      await axios
      .put(`${baseURL}/api/partner/update/${id}`,formData, 
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + user,
        },
      })
      .then( async (res)=>{
        if (res.status === 201) {
          store.dispatch("Partner/setPartners");
          router.push({ name: "partner" });
        }
      } )
    },  

    async delete(context, id){
     const user = JSON.parse(localStorage.getItem("accesstoken"));

      await axios 
      .delete(`${baseURL}/api/partner/delete/${id}`, 
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + user,
        },
      })
      .then(async (res)=>{
        if (res.status === 201) {
          store.dispatch("Artist/setPartners");
        router.replace({ name: "partner" });
        }
      })
    }
  },
  getters: {},
};
