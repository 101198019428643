import { createStore } from "vuex";
import Auth from "./modules/auth.store";
import Contact from "./modules/contact.store";
import Hero from "./modules/Hero.store"
import Artist from "./modules/artist.store"
import Service from "./modules/service.store"
import Partner from "./modules/partner.store"
import Gallary from "./modules/gallary.store"
import Portfolio from "./modules/portfolio.store"
import Advert from "./modules/advert.store"


// const  sidebarResponsiveness = () => {
//     if (window.visualViewport.width = 1024px)
// }

export default createStore({
  modules: {
    // auth,
    Auth:Auth,
    Hero: Hero,
    Artist:Artist, 
    Service:Service,
    Partner:Partner, 
    Gallary:Gallary, 
    Portfolio:Portfolio, 
    Advert:Advert, 
    Contact:Contact
  },
  state: {
    sideBarOpen: false,
   
},
getters: {
    sideBarOpen: state => {
        return state.sideBarOpen
    }
},
mutations: {
    toggleSidebar (state) {
        state.sideBarOpen = !state.sideBarOpen
    }
},
actions: {
    toggleSidebar(context) {
        context.commit('toggleSidebar')
    },
    
},
});
