import axios from "axios";
import router from "../../router/index";
import store from "../index";
const baseURL = process.env.VUE_APP_API_BASE_URL;
export default {
  namespaced: true,
  state: {
    Services: [],
    service: ''
  },
  mutations: {
    SET_ALL_SERVICES(state, payload) {
      state.Services = payload;
    },
    SET_SERVICE(state, payload){
      state.service = payload
    }
  },
  actions: {
   
    async setServices(context) {
     const user = JSON.parse(localStorage.getItem('accesstoken'));
      await axios
        .get(`${baseURL}/api/service/get`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + user,
          },
        })
        .then(async (res) => {
          if (res.status === 200) {
            context.commit("SET_ALL_SERVICES", res.data.data);
          }
        })
        .catch((err) => {
        console.log("Error",err)
        });
    },
    async setService(context, id){
      await axios 
        .get(`${baseURL}/api/service/get/${id}`)
        .then(async (res) => {
          if (res.status === 200) {
            context.commit("SET_SERVICE", res.data.data);
          }
        })
        .catch((err) => {
        console.log("Error",err)
        });
    },
    async create(context, formData) {
     const user = JSON.parse(localStorage.getItem("accesstoken"));   
     for (const value of formData.values()) {
      console.log("formData"  )
       console.log(value);
     }
      await axios
        .post(`${baseURL}/api/service/create`, formData, 
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + user,
          },
        })
        .then(async (res) => {
          if (res.status === 200) {
            store.dispatch("Service/SET_ALL_SERVICES");
            router.replace({ name: "services" });
          }
        })
        .catch((err) => {
        console.log("Error", err)
        });
    }, 
    async update(context, formData){
     const user = JSON.parse(localStorage.getItem("accesstoken"));
     let id = "";
     for (const value of formData.values()) {
      id = value;
    }
      await axios
      .put(`${baseURL}/api/service/update/${id}`,formData, 
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + user,
        },
      })
      .then( async (res)=>{
        if (res.status === 201) {
          store.dispatch("Service/setServices");
          router.push({ name: "services" });
        }
      } )
    },  

    async delete(context, id){
     const user = JSON.parse(localStorage.getItem("accesstoken"));

      await axios 
      .delete(`${baseURL}/api/service/delete/${id}`, 
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + user,
        },
      })
      .then(async (res)=>{
        if (res.status === 201) {
          store.dispatch("Service/setServices");
        router.replace({ name: "services" });
        }
      })
    }
  },
  getters: {},
};
