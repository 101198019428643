import axios from "axios";
import store from "../index";

// const baseURL = process.env.VUE_APP_API_BASE_URL;


const baseURL = process.env.VUE_APP_API_BASE_URL;

export default {
  namespaced: true,
  state: {
    allContacts: [],
    contact: ''
  },
  mutations: {
    SET_ALL_CONTACTS(state, payload) {
      state.allContacts = payload;
    },
    SET_CONTACT(state, payload){
      state.contact = payload
    }
  },
  actions: {
   
    async setAllContacts(context) {
     const user = JSON.parse(localStorage.getItem('accesstoken'));
      await axios
        .get(`${baseURL}/api/contact/get`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + user,
          },
        })
        .then(async (res) => {
          if (res.status === 200) {
            context.commit("SET_ALL_CONTACTS", res.data.data);
          }
        })
        .catch((err) => {
        console.log("Error",err)
        });
    },
    async setContact(context, id){
      await axios 
        .get(`${baseURL}/api/contact/get/${id}`)
        .then(async (res) => {
          if (res.status === 200) {
            context.commit("SET_CONTACT", res.data.data);
          }
        })
        .catch((err) => {
        console.log("Error",err)
        });
    },
    async create(context, ContactData) {
     const user = JSON.parse(localStorage.getItem("accesstoken"));
      
      await axios
        .post(`${baseURL}/api/contact/create`, ContactData, 
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + user,
          },
        })
        .then(async (res) => {
          if (res.status === 200) {
            store.dispatch("Contact/setAllContacts");
          }
        })
        .catch((err) => {
        console.log("Error", err)
        });
    }, 
  },
  getters: {},
};
